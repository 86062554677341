import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import Status from "./components/Status";
import DeliveryDetails from "./components/DeliveryDetails";
import DeliveryProgress from "./components/DeliveryProgress";

import LogoLoLlevo from "./images/lollevo.svg";
import LogoKarri from "./images/karri.svg";

function App() {
  const [order, setOrder] = useState({
    isLoading: false,
    status: null,
    error: false,
  });

  const [orderId, setOrderId] = useState("");
  const [orderValue, setOrderValue] = useState("");
  useEffect(() => {
    if (!orderId) {
      const pathname = window.location.pathname;

      const path = pathname.split("/");

      if (path.length === 0) {
        return;
      }

      if (path[1] === "orden") {
        setOrderId(path[2]);
        setOrderValue(path[2]);
      }
    }
  }, [orderId]);

  useEffect(() => {
    const getOrder = async () => {
      setOrder({ isLoading: true, status: null, error: false });

      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}${orderId}?data_extra=yes`
        );

        setOrder({
          isLoading: false,
          status: result.data.data[0].extraData,
          error: false,
        });
      } catch (error) {
        setOrder({ isLoading: false, status: null, error: true });
      }
    };
    if (orderId !== "") {
      getOrder();
    }
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newOrder = e.target.orden.value;
    setOrderId(newOrder);
    window.history.pushState({}, `Orden ${newOrder}`, `/orden/${newOrder}`);
  };

  return (
    <div className="bg-gray-100 h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Karri {orderId && `- Estado seguimiento - ${orderId}`}</title>
        <link rel="canonical" href={process.env.REACT_APP_URL} />
      </Helmet>
      <div className="bg-tv-blue text-white px-10 py-10">
        <header className="flex justify-center">
          {process.env.REACT_APP_STYLE === "KARRI" && (
            <img src={LogoKarri} alt="LoLlevo" width={200} />
          )}
          {process.env.REACT_APP_STYLE === "LO_LLEVO" && (
            <img src={LogoLoLlevo} alt="LoLlevo" width={300} />
          )}
        </header>
      </div>
      <main className="container mx-auto">
        <div className="container rounded-lg bg-white mt-8 p-8 space-y-4">
          <div className="my-4 text-center" style={{ fontSize: "18px" }}>
            <strong>Seguimiento de Envio </strong>

            {orderId && (
              <strong>
                Nº <span style={{ color: "#1C9996" }}>{orderId}</span>
              </strong>
            )}
          </div>

          <form className="" onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <input
                className="p-6 border-solid w-5/6 bg-gray-200 rounded-lg border-tv-blue text-2xl focus:outline-none focus:ring focus:ring-tv-orange focus:border-tv-orange text-center"
                type="text"
                id="orden"
                name="orden"
                value={orderValue}
                onChange={(e) => setOrderValue(e.target.value)}
                placeholder="Ingresa el número de tu orden, y presiona la tecla Enter"
              />
            </div>
          </form>
          <section className="text-center mt-20">
            {order.isLoading && (
              <p className="text-2xl">Espera un momento...</p>
            )}
            {!order.isLoading && (
              <Status
                status={order.status}
                error={order.error}
                orderId={orderId}
              />
            )}
          </section>

          <div className="flex justify-center">
            {/* ******************************* */}

            {order.status && (
              <DeliveryProgress
                deliveryProgress={order.status.deliveryProgress}
              />
            )}

            {/* ******************************* */}
          </div>
        </div>

        {order.status && (
          <DeliveryDetails
            detailsProgress={order.status.detailsProgress}
            dropoff={order.status.dropoff}
            location={order.status?.location ? order.status?.location : null}
          />
        )}
      </main>
    </div>
  );
}

export default App;
