import TruckWay from "../images/truck-way.svg";
import TruckError from "../images/truck-error.svg";
import TruckOk from "../images/truck-ok.svg";
import Warehouse from "../images/warehouse.svg";
import People from "../images/warehouse.svg";

const getStatusIcon = (status) => {
  switch (status) {
    case "En espera de gestión inicial":
    case "En gestión por centro de distribución":
      return Warehouse;
    case "En gestión para entrega final":
    case "En camino a reparto":
      return TruckWay;
    case "Recogido correctamente":
      return People;
    case "Entregado a receptor":
      return TruckOk;
    case "Gestión cancelada":
    case "Entrega fallida":
    case "Error":
    default:
      return TruckError;
  }
};

function Status({ status, error = false, orderId }) {
  if (status && status.identifier === "" && !error) {
    return (
      <h3 className="text-2xl text-gray-600">
        Ingresa el número de orden para buscar el estado de tu pedido
      </h3>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center text-center mx-auto mt-6">
        <img
          className="mr-6"
          src={getStatusIcon("Error")}
          alt="Karri"
          width="64"
        />
        <h3 className="text-2xl text-red-600">
          No se encontró el pedido {orderId}
        </h3>
      </div>
    );
  }
  return <></>;
}

export default Status;
