const styleOption = process.env.REACT_APP_STYLE === "KARRI" ? "-karri" : "";
// on-route-active-icon-karri.png
// on-route-adctive-icon-karri.png
const getIcon = (isActive, iconName) => {
  const activeclassName = isActive ? "-active-" : "-";
  switch (iconName) {
    case "received":
      return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_BUCKET}/received${activeclassName}icon${styleOption}.png`;
    case "on-route":
      return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_BUCKET}/on-route${activeclassName}icon${styleOption}.png`;
    case "delivered":
      return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_BUCKET}/delivered${activeclassName}icon${styleOption}.png`;
    default:
      return `https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_BUCKET}/failed-progress-icon.png`;
  }
};

function DeliveryProgress({ deliveryProgress }) {
  return (
    <div className="flex justify-center w-full sm:w-5/6">
      <div className="w-full sm:grid-cols-12">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          {deliveryProgress.map((item) => (
            <div className="mx-auto" key={item.icon}>
              <img
                className="mx-auto"
                src={getIcon(item.isActive, item.icon)}
                alt=""
              />
              <p className={`text-center text-${item.icon}`}>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DeliveryProgress;
