const styleOption = process.env.REACT_APP_STYLE === "KARRI" ? "-karri" : "";

function DeliveryDetails({ dropoff }) {
  return (
    <>
      <div className="flex flex-row my-4">
        <div className="pr-4">
          <img
            src={`https://storage.googleapis.com/${process.env.REACT_APP_IMAGES_BUCKET}/location${styleOption}.png`}
            alt="Location"
          />
        </div>
        <div>{dropoff}.</div>
      </div>
    </>
  );
}

export default DeliveryDetails;
