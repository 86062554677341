// src/DisplayMapClass.js
import React from "react";
import HEREMap, { Marker } from "@impargo/react-here-maps";

function DisplayMap({ location }) {

  
  const center = { lat: location.latitude, lng: location.longitude };
  return (
    <>
      <div className="h-52">
        <HEREMap
          apiKey={process.env.REACT_APP_HERE_MAP_APP_KEY}
          center={{ lat: center.lat, lng: center.lng }}
          zoom={50}
          onMapAvailable={() => console.log()}
          hidpi
          animateZoom={true}
          secure
          interactive={true}
        >
          <Marker
            {...center}
            // bitmap="https://upload.wikimedia.org/wikipedia/commons/8/8e/Pan_Blue_Circle.png"
            bitmap='<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="20" fill="none" stroke="#1c9996" stroke-width="3.0583"/></svg>'
          ></Marker>
        </HEREMap>
      </div>
    </>
  );
}

export default DisplayMap;
