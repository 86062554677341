import DeliveryDetailsProgress from "./DeliveryDetailsProgress";
import DeliveryDetailsAddress from "./DeliveryDetailsAddress";
import DisplayMap from "./DisplayMap";

function DeliveryDetails({ dropoff, detailsProgress, location }) {
  return (
    <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 rounded-lg bg-white mt-8 p-8">
      <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:px-32 flex justify-center">
        <div className="flex-1">
          <div className="py-10">
            <strong>Detalles del envío</strong>
          </div>
          <DeliveryDetailsProgress data={detailsProgress} />
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex justify-center">
        <div className="flex-1">
          <div className="py-10">
            <strong>Dirección de entrega</strong>
          </div>
          <DeliveryDetailsAddress dropoff={dropoff} />
          {location ? <DisplayMap location={location} /> : null}
        </div>
      </div>
    </div>
  );
}

export default DeliveryDetails;
