function DeliveryDetailsProgress({ data: detailsProgress }) {
  const styleOption = process.env.REACT_APP_STYLE === "KARRI" ? "-karri" : "";
  const styleCircle = styleOption.replace("-", "");

  return (
    <div>
      <div className="container">
        {detailsProgress &&
          detailsProgress.map((item) => (
            <div className={`step ${item.status}`} key={item.title}>
              <div className="v-stepper">
                <div className={`circle ${styleCircle}`}></div>
                <div className="line"></div>
              </div>

              <div className="content">
                <strong>{item.title}</strong>
                <br />
                {item.description}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default DeliveryDetailsProgress;
